.loginPage {
    background-color: black;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    min-height: 140vh;
    width: 100vw;
  }
  
  .nav {
    display: flex;
    align-items: center;
    background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
    min-height: 14vh;
    width: 100%;
    padding: 24px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: space-around;
    margin: 24px;
  }
  
  .logo {
    /* height: 77px;
    width: 266px; */
    margin-right: 12px;
  }
  
  .registrationText {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-size: 28px;
    line-height: 75px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    background: linear-gradient(91.23deg, #FFFFFF -1.16%, #999999 144.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  }
  


  .tournamentListContainer {
    display: flex;
    margin-top: 32px;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    background-color: #000;
}

.tournamentRow {
    width: 80%;
    background: linear-gradient(180deg, #2C2C2C 0%, #1E1E1E 100%);
    margin: 10px 0;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tournamentLogo img {
    width: 125px;
    height: 125px;
}

.tournamentDetails {
    flex: 1;
    padding:32px;
}

.tournamentDetails h3 {
    margin: 0;
}

.tournamentDetails p {
    margin: 5px 0;
    color: #888;
}

.nameOfTournament
{

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 51px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #FFFFFF;


}

.sponsorText
{
font-family: 'Montserrat';
font-style: italic;
font-weight: 200;
line-height: 18px;
display: flex;
align-items: center;
letter-spacing: 0.1em;
text-transform: uppercase;
color: #FFFFFF;


}

.locationText
{


font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.1em;
text-transform: uppercase;

color: #FFFFFF;


}
.tournamentDate {
    flex: 1;
    text-align: center;
    color: #FFF;

    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 12px;

}

.tournamentActions {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.viewButton, .registerButton {
    background-color: #4DFFA8;
    border: none;
    color: black;
    padding: 10px 20px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}

.locationPin
{
  color: red;
  margin-right: 6px;
}

.viewButton {
    background-color: #D9D9D9;
}

.registerButton {
  background: linear-gradient(140.51deg, #4DFFA8 -44.76%, #2E9965 113.53%);

}

.registerButton:hover, .viewButton:hover {
    background-color: #1E1E1E;
    color: #4DFFA8;
}

  



 /* RESPONSIVE PART */

@media only screen and (max-width: 768px) {
  .nav {
    flex-direction: column;
  }

  .logo {
    margin-top: 16px;
    width: 150px;
    height: auto;
  }

  .registrationText {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
  }

  .logoContainer {
    width: 100%;
    margin: 0;
    justify-content: center;
    margin-bottom: 16px;
    flex-direction: column;
  }

  .tournamentRow {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px;
  }

  .tournamentLogo img {
    width: 80px;
    height: 80px;
  }

  .tournamentDetails {
    padding: 10px 0;
  }

  .tournamentDate {
    padding: 8px 0;
    text-align: left;
    font-size: 14px;
  }

  .tournamentActions {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .viewButton, .registerButton {
    width: 48%;
    padding: 8px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .logo {
    width: 120px;
  }

  .registrationText {
    font-size: 18px;
    line-height: 24px;
  }

  .tournamentRow {
    padding: 8px;
  }

  .tournamentLogo img {
    width: 70px;
    height: 70px;
  }

  .tournamentDetails h3 {
    font-size: 18px;
    line-height: 24px;
  }

  .tournamentDetails p {
    font-size: 12px;
  }

  .tournamentDate {
    font-size: 12px;
  }

  .viewButton, .registerButton {
    padding: 6px;
    font-size: 12px;
  }
}
