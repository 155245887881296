.signupPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1e1e1e;
  width: 100vw;
}

.nav {
  display: flex;
  align-items: center;
  background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  height: 18vh;
  width: 100%;
}

.formGroup
{
  width: 100%;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-around;
}

.logo {
  height: 77px;
  width: 266px;
  margin-right: 12px;
}

.registrationText {


font-family: 'Open Sans';
font-style: italic;
font-weight: 700;
font-size: 32px;
line-height: 82px;
/* identical to box height */
display: flex;
align-items: center;
letter-spacing: 0.21em;
text-transform: uppercase;

/* silver header font */
background: linear-gradient(91.23deg, #FFFFFF -1.16%, #999999 144.98%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.centerContainer {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  background-color: #000;
}

.formContainer {
  position: relative;
  padding: 24px 48px 24px 48px;
  width: 40%;
  background: linear-gradient(to bottom right, #848484, #1E1E1E);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 32px;
  margin-top: 64px;
}

.form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* gap: 16px; */
}

.formContainer::before {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 4px solid transparent;
  border-image: linear-gradient(to bottom right, #4DFFA8, #2E9965, #4DFFA8);
  border-image-slice: 1;
  border-radius: 10px;
}

.disabledText
{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #FFFFFF;
}
.inputField {
  position: relative; /* Ensure it stays on top */
  z-index: 1;         /* Higher index to stay above background */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: left;
  text-align: left;
  letter-spacing: 0.24em;
  color: #D9D9D9;
  padding: 12px;
  margin-bottom: 16px;
  /* border-radius: 12px; */
  box-sizing: border-box;
  outline: none;
  width: 100%;
  background: #484848;
  border: 2px solid grey;
}

.submitButton {
  font-family: 'Montserrat';
  z-index: 1;         /* Higher index to stay above background */
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 37px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: none;
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 40%; /* Adjust width as needed */
  box-sizing: border-box;
  background: linear-gradient(142.38deg, #4DFFA8 -3.29%, #2E9965 97.71%, #4DFFA8 102.96%);
  margin-bottom: 24px;
  margin-top: 10px;
}

.signupText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 24px;
}

.roleToggle {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
}

.toggleButton {
  background-color: transparent;
  border: 1px solid #4DFFA8;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #D9D9D9;
  padding: 12px 24px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
  z-index: 100;
}

.toggleButton.active {
  background-color: #4DFFA8;
  color: #000000;
}


.uploadField {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: left;
  text-align: left;
  letter-spacing: 0.24em;
  color: #D9D9D9;

  /* border-radius: 12px; */
  box-sizing: border-box;
  outline: none;
  width: 100%;


  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.24em;
  color: #D9D9D9;
  /* border-radius: 12px; */
  box-sizing: border-box;
  outline: none;
  width: 100%;
 
}


.screenshotText
{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: left;
  text-align: left;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #D9D9D9;
}

.uploadField span {
  flex-grow: 1;
  text-align: left;
}
.fileLabel {
  color: #D9D9D9;
  margin-bottom: 6px;
}

.fileInputWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.fileName {
  font-size: 14px;
  color: #D9D9D9;
  margin-left: 8px;
  font-style: italic;
}

/* Adjustments for .uploadField for consistency */

.uploadField label {
  color: #D9D9D9;
  border-radius: 8px;
}

.fileInput {
  font-size: 16px;
  color: #fff;
  padding: 8px;
  background: #484848;
  border: 2px solid grey;
  border-radius: 8px;
  cursor: pointer;
  flex-grow: 1;
  width: 100%;
}
.uploadField label {
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
}




.errorText {
  color: #ff4d4d;
  font-size: 14px;
  margin-bottom: 16px;
  width: 100%;
}


/* Responsivity */
@media only screen and (max-width: 768px) {
  .nav {
    flex-direction: column;
    height: fit-content;
    /* padding: 24px; */
  }
  .centerContainer {
    padding: 4px;
  }
  .logo{
    margin-top: 16px;
    width: 150px;
    height: auto;
  }
  .registrationText
  {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 12px;
    margin-top: 32px;
  } 
  .signupText
  {
    margin-top: 36px;
    margin-bottom: 18px;
  }
  .logoContainer {
    width: 100%;
    margin: 0;
    justify-content: center;
    margin-bottom: 16px;
    flex-direction: column; 
  }

  .formContainer {
    width: 90%;
    margin-top: 32px;
  }


  .inputField {
    font-size: 18px;
    line-height: 22px;
    padding: 10px;
  }
  .uploadField {
    font-size: 18px;
    line-height: 22px;
  }


  .submitButton {
    font-size: 24px;
    padding: 12px 16px;
    width: 60%;
  }

  .toggleButton {
    font-size: 18px;
    padding: 10px 20px;
  }

  .uploadField label {
    font-size: 22px;
  }

  .screenshotText {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .formContainer {
    width: 90%;
    margin-top: 16px;
  }

  .signupText {
    font-size: 28px;
    line-height: 34px;
  }

  .registrationText
  {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 32px;
  }

  .inputField {
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
  }

  .uploadField {
    font-size: 16px;
    line-height: 20px;
  }

  .submitButton {
    font-size: 20px;
    padding: 10px 14px;
    width: 80%;
  }

  .toggleButton {
    font-size: 16px;
    padding: 8px 16px;
  }

  .uploadField label {
    font-size: 16px;
  }

  .screenshotText {
    font-size: 16px;
  }
}
