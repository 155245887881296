.dashboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 140vh;
  background-color: black;
  width: 100vw;
}

.uploadedSport {
  background-color: #00ff00; /* Example color for uploaded sports */
}

.nav {
  display: flex;
  align-items: center;
  background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  min-height: 14vh;
  width: 100%;
  padding: 24px;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 45%;
  justify-content: space-around;
  margin: 24px;
}

.logo {
  /* height: 77px;
  width: 266px; */
  margin-right: 12px;
}

.registrationText {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-left: 10px;
}

.ribbon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 12%;
  margin-top: 5vh;
  margin-bottom: 5vh;
  background-image: url("../assets/ribbon.png");
  background-size: fill;
  background-repeat: no-repeat;
  overflow: hidden; /* Ensures text doesn't overflow the ribbon */
}

.ribbonText {
  font-family: 'Montserrat', 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 61px;
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: left;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  max-width: 95%; /* Ensures text doesn't overflow */
  padding: 0 10%; /* Adds horizontal padding to center text */
  
  /* Grey gradient */
  background: linear-gradient(142.91deg, #848484 -3.97%, #1E1E1E 49.32%, #848484 101.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


.sportsListContainer {
  padding: 24px;
  width: 90%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
}

.sportsGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.sportCard {
  background-color: transparent; /* Default background color */
  border: 4px solid transparent;
  border-image: linear-gradient(to bottom right, #000000, #808080) 1;
  padding: 64px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s, border-color 0.3s, background-color 0.3s;
  width: 22.5%;
}

/* Additional class to apply when the player list is uploaded */
.sportCard.uploaded {
  border-image: none; /* Remove border gradient when player list is uploaded */
  background: var(--gradient-background); /* Use CSS variable for background */
}


.sportCard:hover {
  transform: scale(1.05);
  border-color: transparent;
}

.fillDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #4DFFA8;
}
.sportName
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;

    color: #FFFFFF;


}
.submitButton {
  font-family: 'Montserrat';
  z-index: 1;         /* Higher index to stay above background */
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
  text-align: center;
  letter-spacing: 0.24em;
  color: #FFFFFF;
  border: none;
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  background: linear-gradient(142.38deg, #4DFFA8 -3.29%, #2E9965 97.71%, #4DFFA8 102.96%);
  margin-top: 24px;
}
@media only screen and (max-width: 1024px) {
  .ribbonText {
    font-size: 28px;
    line-height: 48px;
  }
  .sportName
  {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .sportsGrid{
    width: 95%;
    flex-direction: column;
  }
  .sportsGrid{
    width: 95%;
  }
  .sportCard{
    width: 95%;
  }
  .nav {
    flex-direction: column;
  }

  .sportName {
    font-size: 12px;
  }

  .logo {
    margin-top: 16px;
    width: 150px;
    height: auto;
  }

  .registrationText {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
  }

  .logoContainer {
    width: 100%;
    margin: 0;
    justify-content: center;
    margin-bottom: 16px;
    flex-direction: column;
  }

  .ribbon {
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    padding: 0;
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 5vh;
    background-image: url("../assets/ribbon.png");
    background-repeat: no-repeat;
    overflow: hidden; /* Ensures text doesn't overflow the ribbon */
  }
  
  .ribbonText {
    font-size: 30px;
  }

  .centerContainer {
    height: auto;
    padding: 16px;
  }

  .formContainer {
    width: 80%;
  }

  .signupText {
    font-size: 32px;
    line-height: 40px;
  }

  .inputField {
    font-size: 18px;
    line-height: 22px;
    padding: 10px;
  }

  .submitButton {
    font-size: 24px;
    padding: 12px 16px;
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  .ribbonText {
    font-size: 18px;
  }
  .formContainer {
    width: 90%;
  }

  .signupText {
    font-size: 28px;
    line-height: 34px;
  }

  .inputField {
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
  }

  .submitButton {
    font-size: 20px;
    padding: 10px 14px;
    width: 80%;
  }
}

