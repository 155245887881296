
.pageContainer {
  display: flex;
  flex-direction: column; /* Stack navbars and content vertically */
  width: 100vw;
  height: 140vh;
}
.fantasyHeader {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-size: 59px;
  line-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 5%;
}
.topNavbar1 {
  width: 100%;
  height: 14vh;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topNavbar2 {
  width: 100%;
  height: 6vh;
  background: linear-gradient(90.05deg, #4DFFA8 0%, #EEFF00 60.77%, #4DFFA8 117.99%, #0DBB66 118%);
  color: #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.topNavbar1 a,
.topNavbar2 a {
  font-family: Montserrat;
font-size: 20px;
font-style: italic;
font-weight: 500;
line-height: 24.38px;
letter-spacing: 0.21em;
text-align: left;

}

.topNavbar1 a:hover,
.topNavbar2 a:hover {
  font-family: Montserrat;
font-size: 20px;
font-style: italic;
font-weight: 500;
line-height: 24.38px;
letter-spacing: 0.21em;
text-align: left;

}

.fantasyPage {
  display: flex;
  flex-direction: row; /* Main content area remains a row */
  justify-content: center;
  align-items: flex-start;
  background-color: #1E1E1E;
  flex: 1; /* Take up remaining space below navbars */
  padding-top: 32px;
}


/* Rest of your styles */

.fantasySponsorText {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 16px;
}

.sponsorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.playerSelectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20vw;
}

.playerSelection {
  background-color: #666666;
  width: 20vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: scroll;
}

.moneyLeftContainer {
  background-color: #282828;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
}

.moneyLeft {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.moneyPrice {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.17em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.filterDropdown {
  background-color: #FFFFFF;
  color: #000000;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

.playerItem {
  background-color: #4DFFA8;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.playerPlayerName {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  color: #000000;
}

.playerPlayerPosition {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #484848;
}

.playerPlayerPrice {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #484848;
}

.playerNamePositionContainer {
  display: flex;
  flex-direction: column;
  align-items: right;
}
.plusSign
{
  position: absolute;
  left: 50%;
}

.playerPlayerPriceContainer {
  display: flex;
  align-items: center;
}

.playerImageContainer
{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.playerImage {
  object-fit: contain;
  height: 80%;
}

.buttons
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  height: 10%;
}

.resetButton
{

background: #666666;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
padding: 10px;
font-family: 'Montserrat';
font-style: italic;
font-weight: 900;
font-size: 16px;
cursor: pointer;
margin-top: 10px;
text-transform: uppercase;
width: 20%;
}
.submitButton {
  background-color: #4DFFA8;
  color: #000000;
  border: none;
  padding: 10px;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  text-transform: uppercase;
  width: 20%;
  border-radius: 5px;
}

.submitButton:hover {
  background-color: #3ecc85;
}


.teamContainer {
  display: flex;
  flex-direction: column;
  width: 55vw;
  height: 120vh;
  background-color: #1E1E1E;
  padding: 20px;
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  width: 100%;
  max-height: 90vh;
}

.teamBox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #282828;
  color: #FFF;
  font-family: 'Montserrat';
  font-size: 20px;
  cursor: pointer;
  flex-direction: column;
  box-sizing: border-box; /* Ensures border is included in width/height */
  width: 100%; /* Set a consistent width */
  height: 100%; /* Set a consistent height */
}

.hidden
{
  opacity: 0;
}

.teamBox.selected {
  border: 2px solid #4DFFA8; /* The border is now included in the box size */
  box-sizing: border-box;
}

.teamBox.filled {
  background-color: #4DFFA8;
  position: relative; /* Ensure the box is the positioning context for the pseudo-elements */
}

.teamBox.filled .sportLabel {
  background-color: #282828;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  padding: 5px 10px;
  color: #FFFFFF;
}

.teamBox.filled::before,
.teamBox.filled::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 10px; /* Adjust the height as needed */
  background-color: white;
}

.teamBox.filled::before {
  top: 0; /* Position at the top */
}

.teamBox.filled::after {
  bottom: 0; /* Position at the bottom */
}

.playerImageNamePositionContainer
{
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 80%;
  align-items: center;
  justify-content: center;
}

.teamBox.empty {
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: #FFF;
}

.playerInfoBox
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.teamBox.filled .playerInfoBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: black;
}

.playerNameBox {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  /* font-size: 18px; */
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  color: #000000;
}

.playerNamePositionContainerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 80%;
}

.playerPositionBox {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #484848;
}

.playerPriceBox {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #484848;
}

.playerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playerName,
.playerPosition {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.13em;
  text-transform: uppercase;
}

.playerPrice {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  width: 100%;
  padding-right: 10px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #484848;
}

.teamBoxTextContainer {
  position: absolute;
  top: 5px;
  right: 2%;
  background-color: white;
  padding: 8px;
  padding-left: 18px;
  padding-right: 18px;
}

.teamBoxText {
  color: black;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #000000;
}

.plusSign {
  font-size: 40px;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  color: #000000;
}

.longBox {
  grid-column: span 6;
  max-height: 95%;
}

.halfBox {
  grid-column: span 3;
  height: 95%;
}

.smallBox {
  grid-column: span 2;
  height: 95%;
}

.sportLabel {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #FFFFFF;
  color: #000;
  padding: 5px 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #000000;
}
.logoContainer {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-around;
}

.logo {
  height: 77px;
  width: 266px;
  margin-right: 12px;
}

.registrationText {


font-family: 'Open Sans';
font-style: italic;
font-weight: 700;
font-size: 60px;
line-height: 82px;
/* identical to box height */
display: flex;
align-items: center;
letter-spacing: 0.21em;
text-transform: uppercase;

/* HEADER GREEN */
background: linear-gradient(137.58deg, #4DFFA8 -3.34%, #2E9965 80.5%, #4DFFA8 152.23%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
