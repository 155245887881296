.nav {
  display: flex;
  align-items: center;
  background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  min-height: 14vh;
  width: 100%;
  padding: 24px;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: space-around;
  margin: 24px;
}

.logo {
  /* height: 77px;
    width: 266px; */
  margin-right: 12px;
}

.tournamentSelect {
  color: black;
  position: relative;
  margin-top: 24px;
  right: 34%;
}

.registrationText {


  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 75px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;

  background: linear-gradient(91.23deg, #FFFFFF -1.16%, #999999 144.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4.94118px 4.94118px rgba(0, 0, 0, 0.25);

}

.tournamentPage {
  background-color: #1a1a1a;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 220vh;
  min-width: 100vw;
}

.dropdownContainer {
  top: 20px;
  left: 20px;
  z-index: 1000;
  /* Ensure it appears above other elements */
  background-color: transparent;

}

.tournamentSelect {
  width: 100%;
  color: black;
  background-color: transparent;
}

.topNavbar2 {
  width: 100%;
  height: 6vh;
  background: linear-gradient(90.05deg, #4DFFA8 0%, #EEFF00 60.77%, #4DFFA8 117.99%, #0DBB66 118%);
  color: #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.topNavbar2 a {
  font-family: Montserrat;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 24.38px;
  letter-spacing: 0.21em;
  text-align: left;
  color: black;
}

/* Dashboard Grid */
.dashboardGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  /* Four columns grid */
  grid-template-rows: auto auto auto;
  /* Dynamic rows */
  gap: 18px;
  padding: 20px;
  width: 90%;
  margin: 32px;
  height: 120vh;
}

/* Specific Grid Item Sizing */
.gridItem {
  background: #333333;
  padding: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  border: 1px solid;
  border-image-source: linear-gradient(142.91deg, #848484 -3.97%, #1E1E1E 49.32%, #848484 101.54%);


}

/* Individual Grid Items Size Adjustments */
.gridItem:nth-child(1) {
  /* RULES */
  grid-column: span 2;
  grid-row: span 2;
}

.gridItem:nth-child(2) {
  /* SCHEDULE */
  grid-column: span 6;
  grid-row: span 6;
}

.gridItem:nth-child(3) {
  /* NEWSFEED */
  grid-column: span 2;
  grid-row: span 15;
}

.gridItem:nth-child(4) {
  /* FANTASY */
  grid-column: span 6;
  grid-row: span 10;
  background-image: url("../assets/fantasybg.svg"), linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  background-size: cover;
  /* Ensures the image covers the entire area */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
  /* Centers the image within the div */

}


.gridItem:nth-child(5) {
  /* PLAYERS */
  grid-column: span 3;
  grid-row: span 1;
}

.gridItem:nth-child(6) {
  /* TEAMS */
  grid-column: span 3;
  grid-row: span 1;
}

.gridItem:nth-child(7) {
  /* AUCTION */
  grid-column: span 2;
  grid-row: span 10;
  background-image: url("../assets/auctionbg.svg"), linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  background-size: cover;
  /* Ensures the image covers the entire area */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
  /* Centers the image within the div */
}

.gridItem:nth-child(8) {
  /* RESULTS */
  grid-column: span 3;
  grid-row: span 10;
  background-image: url("../assets/podium.png"), linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  background-size: cover;
  /* Ensures the image covers the entire area */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
  /* Aligns the image to the bottom center */
}


.gridItem:nth-child(9) {
  /* TIMELINE */
  grid-column: span 3;
  grid-row: span 10;
  background-image: url("../assets/timelinebg.svg"), linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  background-size: contain;
  /* Ensures the image covers the entire area */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
  /* Aligns the image to the bottom center */
}

.gridItem:nth-child(10) {
  /* SPONSORS */
  grid-column: span 4;
  grid-row: span 12;
}

.gridItem:nth-child(11) {
  /* GALLERY */
  grid-column: span 4;
  grid-row: span 12;
  /* background-image:  url("../assets/gallerybg.jpeg"), linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%); */
  background-size: contain;
  /* Ensures the image covers the entire area */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
  /* Centers the image within the div */
}

/* Adjust Button Placement for Larger Items */
.gridItem h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  line-height: 55px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #FFFFFF;


}

.gridItem button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(140.51deg, #4DFFA8 -44.76%, #2E9965 113.53%);
  border-radius: 10px;
  width: 150px;
  color: #000;
  font-weight: bold;
  text-decoration: none;

}


/* Responsivity */

@media only screen and (max-width: 768px) {
  .nav {
    flex-direction: column;
    padding: 16px;
  }

  .logo {
    margin-top: 36px;
  }

  .registrationText {
    /* margin-top: 36px;
        margin-bottom: 18px; */
  }

  .logoContainer {
    width: 100%;
    margin: 0;
    justify-content: center;
    margin-bottom: 16px;
    flex-direction: column;
  }

  .topNavbar2 {
    flex-direction: column;
    height: auto;
    padding: 16px 0;
    display: none;
  }

  .topNavbar2 a {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .tournamentSelect {
    margin-top: 16px;
    right: 0;
    /* Adjust based on screen size */
    width: 90%;
  }

  .dashboardGrid {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 12px;
    width: 95%;
    margin: 16px;
    height: 120vh;
    align-items: center;
    margin-top: 16px;
    /* justify-content: center; */
  }

  .tournamentPage {
    min-height: 280vh;
  }

  .gridItem {
    width: 95%;
    height: 20vh;
    margin-bottom: 12px;
    border: 1px solid whitesmoke;
  }

}

.tournamentLogo {
  height: 100px;
  width: auto;
  margin-right: 6vw;
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .tournamentLogo {
    margin: 0 auto;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .nav {
    padding: 12px;
  }

  .registrationText {
    font-size: 20px;
    line-height: 48px;
  }

  .tournamentSelect {
    margin-top: 12px;
    width: 100%;
  }

  .dashboardGrid {
    grid-template-columns: 1fr;
    /* Single column layout for mobile screens */
    gap: 8px;
    padding: 8px;
    width: 100%;
    margin: 8px;
    margin-top: 16px;
    height: auto;
  }

  .gridItem {
    grid-column: span 1;
    /* Each item takes full width on mobile */
    grid-row: span 1;
  }

  .gridItem h3 {
    font-size: 18px;
    line-height: 40px;
  }

  .gridItem button {
    width: 100px;
    /* Smaller buttons for mobile */
  }
}