  .nav {
    display: flex;
    align-items: center;
    background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
    min-height: 14vh;
    width: 100%;
    padding: 24px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: space-around;
    margin: 24px;
  }
  
  .logo {
    /* height: 77px;
    width: 266px; */
    margin-right: 12px;
  }
  
  .registrationText {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 10px;
  }
  
  .topNavbar2 {
    width: 100%;
    height: 6vh;
    background: linear-gradient(90.05deg, #4DFFA8 0%, #EEFF00 60.77%, #4DFFA8 117.99%, #0DBB66 118%);
    color: #000;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .topNavbar2 a {
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 24.38px;
    letter-spacing: 0.21em;
    text-align: left;
  }
  
  .topNavbar
  {
    position: relative;
    align-self: right;
    display: flex;
    flex-direction: column;
    margin: 32px;
    margin-bottom: 4px;
    align-items: center;
    justify-content: center;
  }
  
  .saveButton {
    background-color: #4DFFA8;
    color: #000000;
    border: none;
    padding: 20px;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    font-size: 16px;
    cursor: pointer;
    margin: 16px;
    text-transform: uppercase;
    border-radius: 5px;
  }
  
  .saveButton:hover {
    background-color: #3AE695;
  }
  
  /* Add styles for the auction player display */
  
  .auctionContainer
  {
    background-color: #1a1a1a;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    min-height: 140vh;
    width: 100vw;
  }

  .playerDetails
  {
    margin-top: 10vh;
    border-radius: 9.833px;
    border: 4.916px solid var(--lime-green, #A2FF51);
    /* padding: 20px; */
    height: 50vh;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .nameButtons
  {
    display: flex;
    width: 30%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #000;
    background: var(--lime-green, #A2FF51);
    height: 100%;

  }

  .playerInfo
  {
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    padding: 32px;
    padding-left: 64px;
  }

  .prevButton
  {
    width: 100%;
background: #626262;
color: #FFF;
font-family: Montserrat;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 2.446px;
text-transform: uppercase;
padding: 5px;
  }

  .playerNameContainer
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }
  .nextButton
  {
    width: 100%;
    color: #FFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.446px;
    text-transform: uppercase;
  background: #96D45F;
  padding: 5px;
  

  }
  .buttons{
    display: flex;
    flex-direction: column;
    width: 50%;
    border-radius: 4px;
    border: 3px solid var(--HEADER-GRADIENT, #0000);
    background: #484848;
  }

  .playerNameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px;
  }
  
  .playerName {
    font-family: Montserrat;
    font-style: normal;
    text-transform: uppercase;
    font-size: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .boldNamePart {
    font-weight: 700; /* Bold */
  }
  
  .regularNamePart {
    font-weight: 400; /* Regular */
  }
  
  .playerName
  {
    color: #1E1E1E;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 5.879px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .title
  {
    color: #FFF;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 100;
    line-height: normal;
    letter-spacing: 2.611px;
    text-transform: uppercase;
  }

  .firstName {
    font-weight: 400; /* Regular weight */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .middleName {
    font-weight: 700; /* Higher weight */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  
  .lastName {
    font-weight: 700; /* Regular weight */
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-self: center;
  }
  
  .saveButton
  {
    background: var(--lime-green, #A2FF51);
    margin-top: 32px;
  }

  .titleanswer
  {
    color: #FFF;
font-family: Montserrat;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 3px;
text-transform: uppercase;
  }