.spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  
  .spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid transparent;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    background: linear-gradient(90.05deg, #4DFFA8 0%, #4DFFA8 117.99%, #0DBB66 118%);
    -webkit-mask: radial-gradient(circle, transparent 60%, black 60%);
    mask: radial-gradient(circle, transparent 60%, black 60%);
  }

  .text {
    color: white;
    font-size: 1.2em;
    text-align: center;
    margin-top: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  