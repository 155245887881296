.playerSelectionPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: black;
  width: 100vw;
}

.nav {
  display: flex;
  align-items: center;
  background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  min-height: 14vh;
  width: 100%;
  padding: 24px;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 45%;
  justify-content: space-around;
  margin: 24px;
}

.logo {
  margin-right: 12px;
}

.pageTitle {
  margin-left: 12px;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 75px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  background: linear-gradient(91.23deg, #FFFFFF -1.16%, #999999 144.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.centerContainer {
  display: flex;
  margin-top: 32px;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  background-color: #000;
}

.selectionContainer {
  width: 80%;
  padding: 24px;
  border-radius: 8px;
  background: linear-gradient(to bottom right, #848484, #1E1E1E);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  gap: 32px;
  text-align: center;
}

.headerText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 12px;
}

.sportSection {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
}

.sportTitle {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; /* For non-webkit browsers */
  text-fill-color: transparent;
}


.playersGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-evenly;
  align-items: center;
}

.playerButton {
  position: relative;
  background-color: #333;
  color: white;
  padding: 15px 20px;
  border-radius: 12px;
  border: 2px solid #03ffb3;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.playerButton:hover {
  background: linear-gradient(90.05deg, #4DFFA8 0%, #EEFF00 60.77%, #4DFFA8 117.99%, #0DBB66 118%);
  color: #000;
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.playerButton.activePlayer {
  background: linear-gradient(to bottom right, #4caf50, #1E90FF);
  color: black;
  font-weight: bold;
  border-color: #03ffb3;
  transform: scale(1.1);
}

.navButtons {
  display: flex;
  gap: 20px;
}
.previousButton,
.nextButton  {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
  background: linear-gradient(to bottom right, #4DFFA8, #2E9965);
}


.nextButton:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.previousButton:hover,
.nextButton:hover {
  background: linear-gradient(to bottom right, #45a049, #1E6633);
}
