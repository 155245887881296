/* AdminVerification.module.css */

.adminPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #1a1a1a;
    width: 100vw;
    color: #fff;
  }
  .sectionTitle {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .tournamentList {
    list-style-type: none;
    padding: 0;
    margin-bottom: 24px;
  }
  
  .tournamentItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .tournamentName {
    font-size: 18px;
    color: #d9d9d9;
  }
  
  .viewPlayersButton {
    background-color: #4DFFA8;
    border: none;
    padding: 8px 16px;
    color: #000;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .playersSection {
    margin-top: 32px;
  }
  
  .playersList {
    list-style-type: none;
    padding: 0;
  }
  
  .verificationItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .playerInfo {
    color: #d9d9d9;
    font-size: 16px;
  }
  
  .playerActions {
    display: flex;
    gap: 8px;
  }
  
  .editButton, .deleteButton {
    background-color: #2E9965;
    border: none;
    padding: 6px 12px;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .deleteButton {
    background-color: #ff4d4d;
  }
  
  .inputField {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border-radius: 4px;
    background-color: #484848;
    color: #d9d9d9;
    border: 1px solid #848484;
    z-index: 100;

  }
  
  .submitButton {
    width: 100%;
    padding: 10px;
    background-color: #4DFFA8;
    border: none;
    color: #000;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    z-index: 100;
  }
  
  
  .nav {
    display: flex;
    align-items: center;
    background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
    min-height: 14vh;
    width: 100%;
    padding: 24px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-around;
  }
  
  .logo {
    margin-right: 12px;
  }
  
  .registrationText {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-size: 28px;
    line-height: 75px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    background: linear-gradient(91.23deg, #FFFFFF -1.16%, #999999 144.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .centerContainer {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    background-color: #000;
  }
  
  .formContainer {
    position: relative;
    padding: 24px 48px;
    width: 40%;
    background: linear-gradient(to bottom right, #848484, #1E1E1E);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 32px;
    z-index: 10; /* Ensure this is higher than any other overlays */
  }

  .formContainer::before {
    pointer-events: none; /* Ensure pseudo-elements don’t block interaction */
  }
  
  .inputField, .submitButton, .editButton, .deleteButton {
    position: relative; /* Ensure buttons are positioned on top */
    z-index: 11; /* Should be higher than other nearby elements */
  }
  
  .formContainer::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: 4px solid transparent;
    border-image: linear-gradient(to bottom right, #4DFFA8, #2E9965, #4DFFA8);
    border-image-slice: 1;
    border-radius: 10px;
  }
  
  .inputField {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 22px;
    color: #D9D9D9;
    padding: 12px;
    margin-bottom: 16px;
    width: 100%;
    background: #484848;
    border: 2px solid;
    border-image-source: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  }
  
  .submitButton {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    color: #000;
    background: linear-gradient(142.38deg, #4DFFA8 -3.29%, #2E9965 97.71%);
    padding: 15px 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 40%;
    text-transform: uppercase;
  }
  
  .verificationList {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
  
  .verificationItem {
    background: #333333;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .disabledText {
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  
  /* Responsivity */
  @media only screen and (max-width: 768px) {
    .formContainer {
      width: 80%;
    }
  
    .submitButton {
      font-size: 18px;
      padding: 10px 16px;
      width: 60%;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .formContainer {
      width: 90%;
    }
  
    .registrationText {
      font-size: 20px;
      line-height: 48px;
    }
  
    .submitButton {
      font-size: 16px;
      width: 80%;
    }
  }
  