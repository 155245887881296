.signupPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 140vh;
    background-color: black;
    width: 100vw;
  }
  
  .nav {
    display: flex;
    align-items: center;
    background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
    min-height: 14vh;
    width: 100%;
    padding: 24px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: space-around;
    margin: 24px;
  }
  
  .logo {
    /* height: 77px;
    width: 266px; */
    margin-right: 12px;
  }
  
  .registrationText {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 10px;
  }
  
  .centerContainer {
    display: flex;
    margin-top: 32px;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    background-color: #000;
  }
  
  .formContainer {
    position: relative;
    padding: 24px 48px 24px 48px;
    width: 40%;
    background: linear-gradient(to bottom right, #848484, #1E1E1E);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 32px;

  }
  
  .form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
  }
  .formContainer::before {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: 4px solid transparent;
    border-image: linear-gradient(to bottom right, #4DFFA8, #2E9965, #4DFFA8);
    border-image-slice: 1;
    border-radius: 10px;
  }
  
  .inputField {
    position: relative; /* Ensure it stays on top */
    z-index: 1;         /* Higher index to stay above background */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.24em;
    color: #D9D9D9;
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 12px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
    background: transparent;
    border: 1px solid #4DFFA8;
  }
  
  
  .submitButton {
    font-family: 'Montserrat';
    z-index: 1;         /* Higher index to stay above background */
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;    /* Center vertically */
    text-align: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    padding: 15px 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 40%; /* Adjust width as needed */
    box-sizing: border-box;
    background: linear-gradient(142.38deg, #4DFFA8 -3.29%, #2E9965 97.71%, #4DFFA8 102.96%);
    margin-bottom: 24px;
  }
  
  
  .signupText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 24px;

  }


  /* RESPONSIVITY */

  @media only screen and (max-width: 768px) {
    .nav {
      flex-direction: column;
    }
  
    .logo {
      margin-top: 16px;
      width: 150px;
      height: auto;
    }
  
    .registrationText {
      margin-top: 32px;
      font-size: 20px;
      line-height: 28px;
    }
  
    .logoContainer {
      width: 100%;
      margin: 0;
      justify-content: center;
      margin-bottom: 16px;
      flex-direction: column;
    }
  
    .centerContainer {
      height: auto;
      padding: 16px;
    }
  
    .formContainer {
      width: 80%;
    }
  
    .signupText {
      font-size: 32px;
      line-height: 40px;
    }
  
    .inputField {
      font-size: 18px;
      line-height: 22px;
      padding: 10px;
    }
  
    .submitButton {
      font-size: 24px;
      padding: 12px 16px;
      width: 60%;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .formContainer {
      width: 90%;
    }
  
    .signupText {
      font-size: 28px;
      line-height: 34px;
    }
  
    .inputField {
      font-size: 16px;
      line-height: 20px;
      padding: 8px;
    }
  
    .submitButton {
      font-size: 20px;
      padding: 10px 14px;
      width: 80%;
    }
  }
  
  