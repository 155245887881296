.sportsSelectionPage {
  display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 140vh;
    background-color: black;
    width: 100vw;
}

.nav {
    display: flex;
    align-items: center;
    background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
    min-height: 14vh;
    width: 100%;
    padding: 24px;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 45%;
  justify-content: space-around;
  margin: 24px;
}

.logo {
  /* height: 77px;
  width: 266px; */
  margin-right: 12px;
}

.pageTitle {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.centerContainer {
  display: flex;
    margin-top: 32px;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    background-color: #000;
}

.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 12px;
}

.selectionContainer {
  width: 80%;
  padding: 24px;
  border-radius: 8px;
  background: linear-gradient(to bottom right, #484848, #484848);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  gap: 24px;
}
.selectionContainer {
  position: relative; /* Required for the absolute positioning of the pseudo-element */
  width: 80%;
  padding: 24px;
  border-radius: 8px;
  background: linear-gradient(to bottom right, #848484, #1E1E1E);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  gap: 24px;
  overflow: hidden; /* Ensure the pseudo-element's border stays within the container */
}

.selectionContainer::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border: 4px solid transparent; /* Adjust the border width as needed */
  border-radius: 10px;
  border-image: linear-gradient(to bottom right, #4DFFA8, #2E9965, #4DFFA8);
  border-image-slice: 1;
}


.headerText {
    margin:24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 24px;


}

.sportsList {
  display: flex;
  flex-wrap: wrap;  /* Allow items to wrap onto the next line */
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center; /* Center items if needed */
  color: #FFFFFF;
}

.sportButton {
  position: relative; /* Positioning context for the pseudo-element */
  background-color: transparent;
  color: white;
  padding: 64px 64px 64px 64px; /* Increase padding to make buttons taller */
  border-radius: 8px;
  border: none; /* Remove default border */
  cursor: pointer;
  transition: background-color 0.2s;
  width: calc(25% - 10px); /* 25% width minus gap to fit 4 per row */
  z-index: 1; /* Ensure content stays above the pseudo-element */
  overflow: hidden; /* Hide overflow for a clean border look */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  /* font-size: 20px; */
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
}

.sportButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom right, #848484, #1E1E1E);
  z-index: -1; /* Place behind the button content and below the button's z-index */
  border-radius: 8px; /* Match the button's border-radius */
  padding: 4px; /* Create the border effect */
  box-sizing: border-box; /* Ensure padding is included in dimensions */
  mask: 
    linear-gradient(#fff 0 0) content-box, /* Mask center content area */
    linear-gradient(#fff 0 0);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /* Make inner area transparent */
  mask-composite: exclude;
}


.sportButton.activeSport {
  background-color: #4DFFA8; /* Change background for active state */
  color: #1E1E1E;
}

.sportButton:focus {
  outline: none; /* Remove focus outline */
}

.sportButton:active {
  background-color: rgba(255, 255, 255, 0.5); /* Slightly darker when clicked */
}




.submitButton {
  margin-top: 20px;
  padding: 15px 48px;
  background: linear-gradient(to bottom right, #4DFFA8, #2E9965);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 24px;
  z-index: 1;
}

.inputField {
  position: relative; /* Ensure it stays on top */
  z-index: 100;         /* Higher index to stay above background */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  color: #D9D9D9;
  /* border-radius: 12px; */
  box-sizing: border-box;
  outline: none;
  width: 100%;
  background: #484848;
  border: 2px solid grey;
  padding: 12px;
  margin-top: 4px;
}

@media only screen and (max-width: 768px) {
  .sportButton {
    font-size: 12px;
    width: 80%;
  }
  .nav {
    flex-direction: column;
  }

  .logo {
    margin-top: 16px;
    width: 150px;
    height: auto;
  }

  .pageTitle {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
  }

  .logoContainer {
    width: 100%;
    margin: 0;
    justify-content: center;
    margin-bottom: 16px;
    flex-direction: column;
  }

  .centerContainer {
    height: auto;
    padding: 16px;
  }
  

  .selectionContainer {
    width: 80%;
  }

  .headerText {
    font-size: 32px;
    line-height: 40px;
  }

  .inputField {
    font-size: 18px;
    line-height: 22px;
    padding: 10px;
  }

  .submitButton {
    font-size: 24px;
    padding: 12px 16px;
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  .selectionContainer {
    width: 90%;
  }

  .signupText {
    font-size: 28px;
    line-height: 34px;
  }

  .inputField {
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
  }

  .submitButton {
    font-size: 20px;
    padding: 10px 14px;
    width: 80%;
  }
}


