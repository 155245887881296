.topNavbar2 {
    background: linear-gradient(90.05deg, #4DFFA8 0%, #EEFF00 60.77%, #4DFFA8 117.99%, #0DBB66 118%);
    color: #000;
    width: 100%;
    padding: 0px 24px 0px 24px;
    margin: 0;
    justify-content: space-between;
    align-items: center;
}

.navbarCollapse {
    width: 100%;
}

.navItems {
    display: flex;
    justify-content: space-around; /* Spreads items evenly */
    width: 100%;
    /* font-size: 20px; */
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    color: #000000;
}

.navbar>.container, .navbar>.container-fluid {
    padding-left: 12px;
    padding-right: 12px;
    margin: 0;
    width: 100%;
}

.topNavbar2 .nav-link, 
.topNavbar2 .dropdown-item {
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.21em;
    color: black !important;
}

.topNavbar2 .navbar-toggler {
    background-color: #000;
}
