.tournamentSelect
{
    color: black;
    position: relative;
    margin-top: 24px;
    width: 20%;
}

.nav {
    display: flex;
    align-items: center;
    background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
    min-height: 14vh;
    width: 100%;
    padding: 24px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: space-around;
    margin: 24px;
  }
  
  .logo {
    /* height: 77px;
    width: 266px; */
    margin-right: 12px;
  }

  .registrationText {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 10px;
  }

.tournamentDashboard {
    background-color: #1a1a1a;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 140vh;
    width: 100vw;
}

.topNavbar2 {
    width: 100%;
    height: 6vh;
    background: linear-gradient(90.05deg, #4DFFA8 0%, #EEFF00 60.77%, #4DFFA8 117.99%, #0DBB66 118%);
    color: #000;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.topNavbar2 a {
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 24.38px;
    letter-spacing: 0.21em;
    text-align: left;
}

.topNavbar
{
    position: relative;
    align-self: right;
    display: flex;
    flex-direction: column;
    margin: 32px;
    margin-bottom: 4px;
    align-items: center;
    justify-content: center;
}
.dashboardGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
    padding: 20px;
    margin-top: 20px;
    width: 80%;
}

.dashboardItem {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    box-sizing: border-box;
    background: #484848;
    border-radius: 5px;
    min-height: 120px; /* Adjust the height of each grid item */
}

h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 37px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.dashboardItem button {
font-family: 'Montserrat';
font-style: italic;
font-weight: 400;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.1em;
text-transform: uppercase;

color: #FFFFFF;


}

.dashboardItem button:hover {
    text-decoration: underline;
}

.sponsorItem {
    grid-column: span 2; /* Span 2 columns */
}

.sponsorButtons {
    display: flex;
    flex-direction: row;
    gap: 20px; /* Space between buttons */
    justify-content: space-around;
    ;
}

.uploadIcon {
    margin-left: 10px;
    font-size: 16px;
}

/* Transparent Input */
.transparentInput {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #FFFFFF;
    color: #FFFFFF;
    font-size: 18px;
    padding: 5px;
    width: 80%;
    text-align: center;


font-family: 'Montserrat';
font-style: italic;
font-weight: 400;
/* font-size: 20px; */
line-height: 24px;
display: flex;
align-items: center;
letter-spacing: 0.1em;
/* text-transform: uppercase; */

color: #FFFFFF;


  }
  
  .transparentInput::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .transparentInput:focus {
    outline: none;
    border-bottom: 2px solid #4DFFA8;
  }
  
  /* Save Button */
  .saveButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: row;
    align-items: center;
  }
  
  .saveButton {
    background-color: #4DFFA8;
    color: #000000;
    border: none;
    padding: 20px;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    font-size: 16px;
    cursor: pointer;
    margin: 16px;
    text-transform: uppercase;
    border-radius: 5px;
  }
  
  .saveButton:hover {
    background-color: #3AE695;
  }
  
  .adminInput
  {
    color: #000;
    background: linear-gradient(142.38deg, #4DFFA8 -3.29%, #2E9965 97.71%, #4DFFA8 102.96%);
  }

  /* Responsivity for screens smaller than 768px */
@media only screen and (max-width: 768px) {
  .nav {
      flex-direction: column;
      padding: 16px;
  }
  
  .logo {
      margin-top: 36px;
  }
  
  .registrationText {
      margin-top: 36px;
      margin-bottom: 18px;
  }
  
  .logoContainer {
      width: 100%;
      margin: 0;
      justify-content: center;
      margin-bottom: 16px;
      flex-direction: column;
  }

  .topNavbar2 {
      flex-direction: column;
      height: auto;
      padding: 16px 0;
      display: none;
  }

  .tournamentSelect {
      margin-top: 16px;
      right: 0; /* Adjust based on screen size */
      width: 90%;
  }

  .dashboardGrid {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 12px;
      width: 95%;
      margin: 16px;
      align-items: center;
      justify-content: center;
  }

  .gridItem {
      width: 95%;
      height: auto;
      margin-bottom: 12px;
      border: 1px solid #4DFFA8;
  }

  .saveButtonContainer {
      flex-direction: column;
      gap: 12px;
  }

  .saveButton {
      width: 95%;
  }
}

/* Responsivity for screens smaller than 480px */
@media only screen and (max-width: 480px) {
  .nav {
      padding: 12px;
  }

  .registrationText {
      font-size: 20px;
      line-height: 48px;
  }

  .tournamentSelect {
      margin-top: 12px;
      width: 90%;
  }

  .dashboardGrid {
      gap: 8px;
      padding: 8px;
      width: 100%;
      margin: 8px;
  }

  .dashboardItem {
    width: 100%;
  }

  .dashboardGroup{
    width: 90%;
  }

  .gridItem h3 {
      font-size: 18px;
      line-height: 40px;
  }

  .saveButton {
      width: 100%;
      padding: 10px;
  }
}
