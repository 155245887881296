.loginPage {
    background-color: black;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    min-height: 140vh;
    width: 100vw;
  }
  
  .nav {
    display: flex;
    align-items: center;
    background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
    min-height: 14vh;
    width: 100%;
    padding: 24px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: space-around;
    margin: 24px;
  }
  
  .logo {
    /* height: 77px;
    width: 266px; */
    margin-right: 12px;
  }
  
  .registrationText {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.21em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 10px;
  }
  
  .centerContainer {
    display: flex;
    margin-top: 32px;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    background-color: #000;
  }
  
  .formContainer {
    position: relative;
    padding: 24px;
    width: 40%;
    background: linear-gradient(to bottom right, #848484, #1E1E1E);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    gap: 12px;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    margin-top: 32px;

  }
  
  .formContainer::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 3px solid transparent;
    border-image: linear-gradient(to bottom right, #4DFFA8, #2E9965, #4DFFA8);
    border-image-slice: 1;
    border-radius: 12px;
    z-index: -1;
  }
  
  .signupText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 16px;
  }
  
  .customGoogleButton {
    background: linear-gradient(to bottom right, #4DFFA8, #2E9965, #4DFFA8);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: background 0.3s ease;
    z-index: 2;
  }
  
  .customGoogleButton:hover {
    background: linear-gradient(to bottom right, #2E9965, #4DFFA8, #2E9965);
  }
  
  .inputField {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.24em;
    /* text-transform: uppercase; */
    color: #D9D9D9;
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 12px;
    box-sizing: border-box;
    outline: none;
    width: 100%; /* Adjust width as needed */
    background: transparent;
    border: 1px solid #4DFFA8;
  }
  
  .forgotPasswordLink {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 17.1111px;
    line-height: 21px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;

    /* another green */
    background: linear-gradient(91.18deg, #9AE4C0 -10.3%, #4DFFA8 29.85%, #88DDB3 66.66%, #4DFFA8 101.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .loginButton {
    padding: 10px 20px;
    background-color: #4DFFA8;
    border: none;
    border-radius: 20px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;

    box-sizing: border-box;
    background: linear-gradient(142.38deg, #4DFFA8 -3.29%, #2E9965 97.71%, #4DFFA8 102.96%);
    border-radius: 5px;

    

  }
  
  .orSeparator {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    color: white;
  }
  
  .orSeparator span {
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .line {
    flex-grow: 1;
    height: 1px;
    background-color: white;
  }
  
  .signupLink {
    margin: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    /* font-size: 22px; */
    line-height: 27px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .signUpLinkText
  {
    text-decoration: underline;
    color: #4DFFA8;
  }
  



  /* RESPONSIVE PART */

  @media only screen and (max-width: 768px) {
    .nav {
      flex-direction: column;
    }
  
    .logo {
      margin-top: 16px;
      width: 150px;
      height: auto;
    }
  
    .registrationText {
      margin-top: 32px;
      font-size: 20px;
      line-height: 28px;
    }
  
    .logoContainer {
      width: 100%;
      margin: 0;
      justify-content: center;
      margin-bottom: 16px;
      flex-direction: column;
    }
  
    .centerContainer {
      height: auto;
      padding: 16px;
    }
  
    .formContainer {
      width: 80%;
      padding: 32px;
    }
  
    .signupText {
      font-size: 32px;
      line-height: 40px;
    }
  
    .inputField {
      font-size: 18px;
      line-height: 22px;
      padding: 10px;
    }
  
    .loginButton {
      font-size: 14px;
      padding: 8px 16px;
    }
  
    .orSeparator {
      margin: 16px 0;
    }
  
    .forgotPasswordLink,
    .signupLink {
      font-size: 14px;
      line-height: 18px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .formContainer {
      width: 90%;
    }
  
    .signupText {
      font-size: 28px;
      line-height: 34px;
    }
  
    .inputField {
      font-size: 16px;
      line-height: 20px;
      padding: 8px;
    }
  
    .loginButton {
      font-size: 12px;
      padding: 6px 12px;
    }
  
    .forgotPasswordLink,
    .signupLink {
      font-size: 12px;
      line-height: 16px;
    }
  }
  