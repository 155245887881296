.nav {
  display: flex;
  align-items: center;
  background-color: #484848;
  height: 18vh;
  width: 100%;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-around;
}

.logo {
  height: 77px;
  width: 266px;
  margin-right: 12px;
}

.registrationText
{

font-family: 'Open Sans';
font-style: italic;
font-weight: 700;
font-size: 20px;
line-height: 75px;
/* identical to box height */
display: flex;
align-items: center;
letter-spacing: 0.21em;
text-transform: uppercase;

/* silver header font */
background: linear-gradient(91.23deg, #FFFFFF -1.16%, #999999 144.98%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.adminDashboard {
  background-color: #1a1a1a;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
}

.topNavbar2 {
  width: 100%;
  height: 6vh;
  background: linear-gradient(90.05deg, #4DFFA8 0%, #EEFF00 60.77%, #4DFFA8 117.99%, #0DBB66 118%);
  color: #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.topNavbar1 a,
.topNavbar2 a {
  font-family: Montserrat;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 24.38px;
  letter-spacing: 0.21em;
  text-align: left;
}

.gameForm {
  border-radius: 12px;
  background: linear-gradient(142.91deg, #848484 -3.97%, #1E1E1E 49.32%, #848484 101.54%);
  padding: 24px;
  margin: 12px;
  width: 60%;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.adminDashboardForm, .gameCard {
  background-color: #282828;
  border: 1px solid #4dffa8;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 0 10px rgba(77, 255, 168, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.adminInput, label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  /* line-height: 37px; */
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  /* text-transform: uppercase; */
  color: #FFFFFF;
}

.adminInput {
  background-color: #fff;
  color: #000;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 12px;
  border: none;
  font-size: 1rem;
}

.adminButton {
  background-color: #4dffa8;
  color: #1a1a1a;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: none;
}

.adminButton:hover {
  background-color: #3cb371;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  align-items: flex-start;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .fantasyTitle {
    font-size: 2rem;
  }

  .adminInput, .adminButton {
    width: calc(100% - 20px);
    font-size: 0.9rem;
    padding: 8px;
  }

  .gameCard {
    width: 100%;
    padding: 10px;
  }
}
