.formSubmissionPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: black;
  width: 100vw;
}

.nav {
  display: flex;
  align-items: center;
  background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  min-height: 14vh;
  width: 100%;
  padding: 24px;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 45%;
  justify-content: space-around;
  margin: 24px;
}

.logo {
  margin-right: 12px;
}

  
.pageTitle {
  margin-left: 12px;
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  line-height: 75px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  background: linear-gradient(91.23deg, #FFFFFF -1.16%, #999999 144.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}


.centerContainer {
  display: flex;
  margin-top: 32px;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  background-color: #000;
}

.formContainer {
  width: 80%;
  padding: 24px;
  border-radius: 8px;
  background: linear-gradient(to bottom right, #848484, #1E1E1E);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.headerText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
  text-align: center;
  color: #FFFFFF;
}

.summarySection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.summaryTitle {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #4DFFA8;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
}

.summaryList {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.playerList {
  padding: 0;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 200;
}

.summaryItem {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.label {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #4DFFA8;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 600;
  margin-bottom: 12px;
}

.inputField {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 2px solid #666;
  background: #333;
  color: #fff;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.navButtons {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.previousButton,
.submitButton {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.previousButton {
  background: linear-gradient(to bottom right, #4DFFA8, #2E9965);
}

.submitButton {
  background: linear-gradient(to bottom right, #4DFFA8, #2E9965);
}

.submitButton:disabled {
  background-color: #555;
  cursor: not-allowed;
}
