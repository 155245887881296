.expanded::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }
  
  .expanded::-webkit-scrollbar-thumb {
    background-color: #4DFFA8; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded edges of the scrollbar thumb */
  }
  
  .expanded::-webkit-scrollbar-track {
    background: #333; /* Color of the scrollbar track */
    border-radius: 10px; /* Rounded edges of the scrollbar track */
  }
  
  /* Custom scrollbar for Firefox */
  .expanded {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #4DFFA8 #333; /* Scrollbar thumb and track colors */
  }
  
  
  .tournamentDashboard {
    background-color: #1a1a1a;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 140vh;
    width: 100vw;
  }
  
  .nav {
    display: flex;
    align-items: center;
    background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
    min-height: 18vh;
    width: 100%;
    padding: 24px;
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
    width: 45%;
    justify-content: space-around;
    margin: 24px;
  }
  
  .logo {
    /* height: 77px;
    width: 266px; */
    margin-right: 12px;
  }
  
  .registrationText {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: "Open Sans";
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 11.55px;
    text-transform: uppercase;
    background: var(--silver-header-font, linear-gradient(91deg, #FFF -1.16%, #999 144.98%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .topNavbar2 {
    width: 100%;
    height: 6vh;
    background: linear-gradient(90.05deg, #4DFFA8 0%, #EEFF00 60.77%, #4DFFA8 117.99%, #0DBB66 118%);
    color: #000;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .topNavbar2 a {
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 24.38px;
    letter-spacing: 0.21em;
    text-align: left;
  }
  
  .topNavbar
  {
    position: relative;
    align-self: right;
    display: flex;
    flex-direction: column;
    margin: 32px;
    margin-bottom: 4px;
    align-items: center;
    justify-content: center;
  }
  
  .dashboardItem ul {
    list-style-type: none;
    padding: 0;
  }
  
  .dashboardItem li {
    margin: 5px 0;
    color: #fff;
  }
  
  .saveButton {
    background-color: #4DFFA8;
    color: #000000;
    border: none;
    padding: 20px;
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    font-size: 16px;
    cursor: pointer;
    margin: 16px;
    text-transform: uppercase;
    border-radius: 5px;
  }
  
  .saveButton:hover {
    background-color: #3AE695;
  }
  
  /* Add styles for the auction player display */
  .auctionPlayer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #444;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    text-align: center;
    color: #fff;
  }
  
  .playerItem {
    background-color: #333;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    flex: 1;
    width: 100%;
  }
  
  .playerInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .playerName {
    color: #FFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    flex-wrap: wrap;
  }
  
  .playerPosition {
    font-size: 16px;
    font-style: italic;
    color: #ffbf00;
    margin-bottom: 5px;
  }
  
  .playerDescription {
    font-size: 14px;
    color: #ddd;
    text-align: center;
  }
  
  .teamsDisplay {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    padding: 64px;
    width: 90%;
  }
  .downArrow {
    width: 10px;
    height: 10px;
  }
  
  .teamDetails {
    margin-top: 10px;
  }
  .team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px #4DFFA8;
    padding: 20px;
    text-align: center;
  }
  .teamPlayerList
  {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0;
    flex: 1;
  }
  
  /* LiveBudgetSheet.module.css */
  
  .liveBudgetSheet {
    background-color: #111; /* Change to match your design */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 140vh;
  }
  
  .teamsDisplay {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
    padding: 48px;
  }
  
  
  
  
  .teamHeader {
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .downArrow {
    width: 20px;
    height: 20px;
  }
  
  .playerItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .budgetSummary {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
  }
  
  .saveButton {
    background-color: #4DFFA8; /* Green button */
    color: black;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 25px;
  }
  
  .saveButton:hover {
    background-color: #3AE695; /* Darker green on hover */
  }
  
  .teamLogoContainer {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .teamLogo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 50%;
  }
  
  /* LiveBudgetSheet.module.css */
  
  .teamCard {
    background-color: #222;
    border-radius: 8px;
    padding: 16px;
    margin: 10px;
    width: calc(25% - 40px); /* 4 teams per row */
    color: #fff;
    border: 3px solid #4DFFA8;
    cursor: pointer;
    overflow: hidden; /* Ensures content does not overflow when collapsed */
    transition: max-height 0.3s ease; /* Smooth transition for expanding */
    max-height: 120px; /* Fixed height when collapsed */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Expanded state styles */
  .expanded {
    max-height: 600px; /* Set a max height to keep content contained */
    overflow-y: auto; /* Adds vertical scroll if content overflows */
    transition: max-height 0.5s ease; /* Smooth transition for expanding */
  }
  
  .teamHeader {
    font-weight: bold;
    display: flex;
    justify-content: space-evenly;
    align-items: center; /* Vertically center the contents */
    padding: 2px; /* Adjust padding to make it look better */
    color: #FFF;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    width: 100%;
  }
  
  .downArrow {
    width: 20px;
    height: 20px;
  }
  
  .teamDetails {
    margin-top: 10px;
    padding: 10px; /* Add padding for better spacing inside the scroll area */
  }
  
  .playerList {
    border-top: 2px solid whitesmoke;
    margin-top: 10px;
    padding-top: 10px;
  }
  
  .playerListHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: bold;
    color: #FFF;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin: 4px;
  margin-bottom: 24px;
  }
  
  .playerItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }
  
  .budgetSummary {
    margin-top: 32px;
    font-size: 14px;
    text-align: center;
  }
  
  .moneyText
  {
    color: #FFF;
  font-family: Montserrat;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  font-size: 16px;
  }
  
  
  .teamLogoSmall {
    margin-right: 10px;
    object-fit: cover;
    width: 65px;
    height: 65px;
  }
  
  .teamHeader {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
    color: #FFF;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  
  .teamDetails {
    margin-top: 10px;
    padding: 10px;
  }
  
  .playerPrice
  {
    color: #FFF;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  }
  
  .playerList {
    border-top: 2px solid whitesmoke;
    margin-top: 10px;
    padding-top: 10px;
  }
  
  .playerListHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: bold;
    color: #FFF;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin: 4px;
    margin-bottom: 24px;
  }
  
  
  
  .playerItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .budgetSummary {
    margin-top: 32px;
    font-size: 14px;
    text-align: center;
  }
  
  .moneyText {
    color: #FFF;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    font-size: 16px;
  }
  
  
  @media screen and (max-width: 768px) {
    .nav {
      flex-direction: column;
      min-height: 12vh;
      padding: 12px;
    }
  
    .logoContainer {
      width: 100%;
      justify-content: center;
      margin: 12px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  
    .registrationText {
      font-size: 16px;
      letter-spacing: 6px;
    }
  
    .teamsDisplay {
      flex-direction: column;
      padding: 20px;
      width: 90%;
        align-items: center;
  
    }
  
    .teamCard {
      width: 90%;
      margin-bottom: 16px;
    }
  
    .teamHeader h4 {
      font-size: 18px;
      margin: 0 10px;
    }
  
    .teamLogoSmall {
      /* width: 50px;
      height: 50px; */
    }
  
    .teamDetails, .playerListHeader, .playerItem {
      font-size: 14px;
    }
  
    .saveButton {
      padding: 10px;
      font-size: 14px;
      width: 90%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .logoContainer {
      margin: 8px;
    }
  
    .registrationText {
      font-size: 14px;
      letter-spacing: 4px;
    }
  
    .teamsDisplay {
      flex-direction: column;
      padding: 10px;
      width: 100%;
    }
  
    .teamCard {
      width: 90%;
    }
  
    .saveButton {
      padding: 8px;
      font-size: 12px;
    }
  
    .playerListHeader, .playerItem {
      font-size: 12px;
    }
  }