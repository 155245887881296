.dashboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 140vh;
  background-color: black;
  width: 100vw;
}

.nav {
  display: flex;
  align-items: center;
  background: linear-gradient(144.74deg, #848484 -24.73%, #1E1E1E 95.14%);
  min-height: 14vh;
  width: 100%;
  padding: 24px;
}

.logoContainer {
  display: flex;
  align-items: center;
  width: 45%;
  justify-content: space-around;
  margin: 24px;
}

.logo {
  /* height: 77px;
  width: 266px; */
  margin-right: 12px;
}

.registrationText {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-left: 10px;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.ribbon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 12%;
  margin-top: 5vh;
  margin-bottom: 5vh;
  background-image: url("../assets/ribbon.png");
  background-size: fill;
  background-repeat: no-repeat;
  overflow: hidden; /* Ensures text doesn't overflow the ribbon */
}

.ribbonText {
  font-family: 'Montserrat', 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: left;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  max-width: 95%; /* Ensures text doesn't overflow */
  padding: 0 10%; /* Adds horizontal padding to center text */
  
  /* Grey gradient */
  background: linear-gradient(142.91deg, #848484 -3.97%, #1E1E1E 49.32%, #848484 101.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.formContainerCoach {
  width: 80%;
  padding: 32px 48px 32px 48px;
  border-radius: 10px;
  background: linear-gradient(to bottom right, #848484, #1E1E1E);
}

.coachRegistrationGrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.input {
  position: relative; /* Ensure it stays on top */
  z-index: 1;         /* Higher index to stay above background */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;

  color: rgba(217, 217, 217, 0.8);


  padding: 12px;
  margin-bottom: 16px;
  /* border-radius: 12px; */
  box-sizing: border-box;
  outline: none;
  width: 90%;
  background: transparent;
  border: 1px solid #4DFFA8;

box-sizing: border-box;

background: linear-gradient(141.55deg, #848484 -24.4%, #1E1E1E 96.23%);
border-radius: 5px;

}

.genderInput {
  display: flex;
  gap: 10px; /* Space between buttons */
}

.genderLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  cursor: pointer;
  background: #D9D9D9;
  border-radius: 40px;
  color: white; /* Text color */
  font-weight: bold;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  /* font-size: 22px; */
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #000000;


}

/* Hides the actual radio button */
.genderInput input[type="radio"] {
  display: none;
}

/* Styles to apply when a radio button is checked */
.genderInput input[type="radio"]:checked + .genderLabel {
  background: grey; /* Lighter background for selected button */
  color: black; /* Change text color for contrast */
}


.sportName {
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 45px;
line-height: 55px;
/* identical to box height */
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.24em;
text-transform: uppercase;
color: #FFFFFF;

}

.submitButton {
  font-family: 'Montserrat';
  z-index: 1;         /* Higher index to stay above background */
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
  text-align: center;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: none;
  padding: 15px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 40%; /* Adjust width as needed */
  box-sizing: border-box;
  background: linear-gradient(142.38deg, #4DFFA8 -3.29%, #2E9965 97.71%, #4DFFA8 102.96%);
  margin-bottom: 6px;
}

.downloadButton {
  font-family: 'Montserrat';
  z-index: 1;         /* Higher index to stay above background */
  font-style: normal;
  font-size: 12px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 25%; /* Adjust width as needed */
  box-sizing: border-box;
  background: linear-gradient(142.38deg, #4DFFA8 -3.29%, #2E9965 97.71%, #4DFFA8 102.96%);
  margin-bottom: 6px;
}

.submitButton:hover {
  background-color: #3cb371;
}
@media only screen and (max-width: 1024px) {
  .ribbonText {
    font-size: 28px;
    line-height: 48px;
  }
  .sportName
  {
    font-size: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .formContainerCoach{
    width: 95%;
  }
  .input{
    font-size: 18px;
  }
  .coachRegistrationGrid{
    font-size: 12px;
    width: 95%;
    padding: 1%;
  }
  .sportsGrid{
    width: 95%;
    flex-direction: column;
  }
  .sportsGrid{
    width: 95%;
  }
  .sportCard{
    width: 95%;
  }
  .nav {
    flex-direction: column;
  }

  .sportName {
    font-size: 24px;
  }

  .logo {
    margin-top: 16px;
    width: 150px;
    height: auto;
  }

  .registrationText {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
  }

  .logoContainer {
    width: 100%;
    margin: 0;
    justify-content: center;
    margin-bottom: 16px;
    flex-direction: column;
  }

  .ribbon {
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    padding: 0;
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 5vh;
    background-image: url("../assets/ribbon.png");
    background-repeat: no-repeat;
    overflow: hidden; /* Ensures text doesn't overflow the ribbon */
  }
  
  .ribbonText {
    font-size: 30px;
  }

  .centerContainer {
    height: auto;
    padding: 16px;
  }

  .formContainer {
    width: 80%;
  }

  .signupText {
    font-size: 32px;
    line-height: 40px;
  }

  .inputField {
    font-size: 18px;
    line-height: 22px;
    padding: 10px;
  }

  .submitButton {
    font-size: 24px;
    padding: 12px 16px;
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  .ribbonText {
    font-size: 18px;
  }
  .formContainer {
    width: 90%;
  }

  .signupText {
    font-size: 28px;
    line-height: 34px;
  }

  .inputField {
    font-size: 16px;
    line-height: 20px;
    padding: 8px;
  }

  .submitButton {
    font-size: 20px;
    padding: 10px 14px;
    width: 80%;
  }
}

